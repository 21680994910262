import React from "react"
import { useParams, Link } from "react-router-dom"
import { useFetchStoreVars } from "Fetch"
import PageBuilder from "components/PageBuilder"
import Loading from "blocks/Loading"
import SectionHeader from "blocks/SectionHeader"
import BodyText from "blocks/BodyText"
import PageHeader from "blocks/PageHeader"
import Hero from "blocks/Hero"
import {
  teamPage as pageQuery,
  jobPosts
} from "queries"

import ArticleLayout from "components/ArticleLayout"

const Join = () => {
  const params = useParams()

  const jobIndex = useFetchStoreVars(pageQuery, { slug: "join-our-team" }, "join")
  const data = useFetchStoreVars(jobPosts, { slug: params.job ? params.job : null }, `jobs-${params.job ? params.job : 'all'}`)

  if (params.job) {
    return (jobIndex && data) ? <Single job={data.entries[0]} others={jobIndex.entries} /> : <Loading />
  } else {
    return (jobIndex && data) ? <Page content={jobIndex.entries} data={data} /> : <Loading />
  }
}

const Single = ({ job, others }) => {
  return <section>
    <ArticleLayout side="right">
      <div>
        <PageHeader className="lift">
          <h1>{job.title}</h1>
          <h2>{job.heading}</h2>
        </PageHeader>
        <div className="article">
          <BodyText body={job.body} />
        </div>
      </div>
      <div className="plain-text" style={{ marginTop: "1rem" }}>
        <div className="sticky">
          <SectionHeader className="" heading={"Qualifications"} />
          {job.longCopy.split(/\n/).map(qual => <p key={qual}>{qual}</p>)}
        </div>
      </div>
    </ArticleLayout>
  </section>
}

const JobCard = ({ job }) => {
  return <Link to={`/${job.uri}`} className="card resource-card card-no-image job-card pad" title={`go to ${job.title} page`}>
    <h3>{job.title}</h3>
    <p>{job.heading}</p>
    <p className="inline-link">
      Read More
      <span className="arrow" />
    </p>
  </Link>
}

const Jobs = ({ data }) => {
  return <ul className="flex-list list-length--3 pad-top">
    {data.map(job => <li key={job.id}><JobCard job={job} /></li>)}
  </ul>
}


const Page = ({ content, data }) => {
  const entry = content[0]
  const hero = entry.heroModule[0]

  return <section>
    <Hero
      image={hero.image}
      heading={hero.heading}
      copy={hero.copy}
      styleType={"default"}
    />

    <div className="max-width">
      <SectionHeader className="large" heading={"Job Openings"} />
      {data?.entries?.length ? <Jobs data={data.entries} /> :
        <div className="flex center">
          <h4>No job openings at this time.</h4>
        </div>
      }
    </div>

    <div className="pad-top">
      <PageBuilder data={entry.pageBuilder} />
    </div>
  </section>
}

export default Join
