import React from "react"
import { useParams, Link } from "react-router-dom"
import { useFetchStoreVars } from "Fetch"
import PageHeader from "blocks/PageHeader"
import Image from "blocks/Image"
import Frame from "blocks/Frame"
import BodyText from "blocks/BodyText"
import Button from "blocks/Button"
import Sidebar from "blocks/Sidebar"
import Loading from "blocks/Loading"
import ArticleLayout from "components/ArticleLayout"
import Related from "components/Resources/Related"
import News from "svgr/News"
import { splitTag } from "helpers"
import useBreakpoint from "useBreakpoint"
import { newsQuery, relatedNews } from "./query"
import checkAnchor from "checkAnchor"

export default () => {
  let { slug } = useParams()
  const data = useFetchStoreVars(newsQuery, { slug: slug }, `news-${slug}`)
  return data ? <SetNewsLayout data={data} /> : <Loading />
}

const SetNewsLayout = ({ data }) => {
  if (!data.entries.length) return null
  const entry = data.entries[0]
  const typeLabel = entry.storyType ? entry.storyType : (entry.newsTag ? entry.newsTag[0].title : entry.typeHandle)
  const author = typeLabel !== "action" ? entry.author : null
  let tags = entry.relatedTag ? entry.relatedTag.map(tag => parseInt(tag.id)).filter(n => n) : []

  checkAnchor()

  return <section>
    {entry.imageAsBackground && entry.image[0]
      ? <HeroLayout
        entry={entry}
        typeLabel={typeLabel}
        author={author}
        typeFilter={tags}
      />
      : <StandardLayout
        entry={entry}
        typeLabel={typeLabel}
        author={author}
        typeFilter={tags}
      />
    }

    {typeLabel === "action" ? <Related current={entry.id} relatedTo={tags} limit={3} manualResources={entry.manualResources} /> : null}
  </section>
}

const TypeLabel = ({ type }) => {
  return type === "action" ? <ul className="breadcrumbs red">
    <li><Link to={"/news"} title={`go to news page`}><span>News</span></Link></li>
    <li><span>Action</span></li>
  </ul> : <h3 className="tag resource-tag">{splitTag(type)}</h3>
}

const ActionButtons = ({ buttons }) => (<div className="button-actions pad-top pad-bottom">
  {buttons.map(link => <Button key={`${link.typeHandle}_${link.id}`} {...link} />)}
</div>)

const StandardLayout = ({ entry, author, typeLabel, typeFilter }) => {
  let image = entry.image[0] ? entry.image[0] : null
  let mobile = useBreakpoint() !== "desktop"

  let params = entry.newsTag ? {
    heading: `Other ${splitTag(typeLabel)}s`,
    query: relatedNews,
    current: entry.id,
    relatedTo: typeFilter,
    tag: entry.newsTag[0].id,
    max: 4,
    limit: 4
  } : {
      heading: ["story", "profile"].indexOf(entry.typeHandle) > -1 ? "Related Stories" : "Related News",
      query: relatedNews,
      current: entry.id,
      relatedTo: typeFilter,
      type: ["story", "profile"].indexOf(entry.typeHandle) > -1 ? entry.typeHandle : ["news", "action"],
      max: 4,
      limit: 4
    }

  return <div className={mobile ? "" : "pad-lg"}>
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={
          <div className="pad-bottom">
            {entry.linkTo && entry.linkTo.length ? <ActionButtons buttons={entry.linkTo} /> : null}
            {image ? <Frame color={"blue"}>
              <Image className="full-width" image={image} />
            </Frame> : <figure className="svg-wrap">
                <News />
              </figure>}
          </div>
        }
        bottom={params}
      />
      <div className="sidebar-stretch">
        <PageHeader className="">
          <TypeLabel type={typeLabel} />
          <h1>{entry.title}</h1>
          {author ? <AuthorBlurb author={author} /> : null}
          <p className="publish-date">{entry.date}</p>
        </PageHeader>
        <div className="article">
          <h2>{entry.heading}</h2>
          {entry.embedOnTop ?
            <>
              <div className="body-text" dangerouslySetInnerHTML={{ __html: entry.embed }} />
              <BodyText body={entry.body} />
            </>
            :
            <>
              <BodyText body={entry.body} />
              <div dangerouslySetInnerHTML={{ __html: entry.embed }} />
            </>
          }
          {entry.linkTo ? <ActionButtons buttons={entry.linkTo} /> : null}
        </div>
      </div>
    </ArticleLayout>
  </div>
}

const AuthorBlurb = ({ author }) => {
  let commas = author.length
  let authorLink = author.map((auth, i) => {

    return auth.typeHandle === "nonStaffMember" ?
      <span
        key={auth.id}
        style={{ lineHeight: `var(--line-body)` }}
        className={commas > 1 && i + 1 !== commas ? "comma-separate" : ""}>
        {author.title}{auth.title}{auth.heading ? `, ${auth.heading}` : ''}
      </span>
      : <Link
        className={`inline-link ${commas > 1 && i + 1 !== commas ? "comma-separate" : ""}`}
        key={`author_${auth.id}`}
        to={`/about/our-team/${auth.slug}`}
        title={`View ${auth.title} bio page`}>
        {auth.title}
      </Link>
  })

  return <div style={{ marginTop: "0.5rem" }}>
    <p>{authorLink}</p>
  </div>
}

const HeroLayout = ({ entry, author, typeLabel, typeFilter }) => {
  const breakpoint = useBreakpoint()
  const style = breakpoint === "desktop" ? {
    background: "var(--white)",
    borderTop: typeLabel === "action" ? "30px solid var(--red)" : "none",
    padding: "3rem",
    paddingRight: "5rem",
    transform: "translateY(-200px)",
    width: "calc(100% + 5rem)"
  } : {
      // borderTop: typeLabel === "action" ? "30px solid var(--red)" : "none"
    }

  let params = entry.newsTag ? {
    heading: `Other ${splitTag(typeLabel)}s`,
    query: relatedNews,
    current: entry.id,
    relatedTo: typeFilter,
    tag: entry.newsTag[0].id,
    max: 4,
    limit: 4
  } : {
      heading: "Related News",
      query: relatedNews,
      current: entry.id,
      relatedTo: typeFilter,
      max: 4,
      limit: 4
    }

  return <React.Fragment>
    <div className="hero">
      <Image className="full-width" image={entry.heroImage[0] ? entry.heroImage[0] : entry.image[0]} />
    </div>
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={
          <div>
            {entry.linkTo ? <ActionButtons buttons={entry.linkTo} /> : null}
          </div>
        }
        bottom={params}
      />
      <div className="offset-hero sidebar-stretch" style={style}>
        <PageHeader className="">
          <TypeLabel type={typeLabel} />
          <h1>{entry.title}</h1>
          {author ? <AuthorBlurb author={author} /> : null}
          <p className="publish-date">{entry.date}</p>
        </PageHeader>
        <div className="article">
          <h2>{entry.heading}</h2>
          {entry.embedOnTop ?
            <>
              <div dangerouslySetInnerHTML={{ __html: entry.embed }} />
              <BodyText body={entry.body} />
            </>
            :
            <>
              <BodyText body={entry.body} />
              <div dangerouslySetInnerHTML={{ __html: entry.embed }} />
            </>
          }
          {entry.linkTo ? <ActionButtons buttons={entry.linkTo} /> : null}
        </div>
      </div>
    </ArticleLayout>
  </React.Fragment>
}
